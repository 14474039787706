import * as PetiteVue from 'petite-vue';
import Swiper from 'swiper/bundle';
import tippy from 'tippy.js';
import Choices from 'choices.js';
import Plyr from 'plyr';
import gsap from 'gsap';

window.PetiteVue = PetiteVue;
window.Swiper = Swiper;
window.tippy = tippy;
window.Choices = Choices;
window.Plyr = Plyr;
window.gsap = gsap;
